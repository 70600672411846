import { useState } from "react";
import { notNullOrUndefined, useSiteContext } from "@equiem/lib";
import type { ApolloError } from "@apollo/client";
import { DateTime } from "luxon";

import type { SiteBookingsQuery, SiteBookingsQueryVariables } from "../generated/gateway-client";
import { useSiteBookingsQuery } from "../generated/gateway-client";
import type { SiteBookingsFilters } from "../pages/operations/hooks/useSiteBookingsFilters";

export type Booking = NonNullable<SiteBookingsQuery["siteBookingsList"]["edges"][number]["node"]>;
type Variables = Omit<SiteBookingsQueryVariables, "page">;

export interface PagedSiteBookingsHookResult {
  bookings: Booking[];
  hasMoreData: boolean;
  error: ApolloError | undefined;
  loading: boolean;
  fetchMore: () => void;
  fetchAll: () => Promise<Booking[] | null>;
}

const DEFAULT_PAGE_SIZE = 30;

export function usePagedSiteBookings(
  filters: SiteBookingsFilters,
  skip?: boolean,
  pageSize = DEFAULT_PAGE_SIZE,
): PagedSiteBookingsHookResult {
  const { uuid: siteUuid, timezone } = useSiteContext();
  const [loadingMore, setLoadingMore] = useState(false);

  const defaultStartDate = DateTime.now().setZone(timezone).startOf("day").toMillis();
  const defaultEndDate = DateTime.now().setZone(timezone).plus({ months: 1 }).endOf("day").toMillis();

  const debouncedVariables: Variables = {
    date: filters.startDate ?? defaultStartDate,
    endDate: filters.endDate ?? defaultEndDate,
    siteUuid: process.env.bookingMultiSiteEnabled === "true" ? null : [siteUuid],
    buildingUuid: filters.buildingUuid,
    levelUuid: filters.levelUuid,
    resourceName: filters.resourceName,
    resourceTypeUuid: filters.resourceTypeUuid,
    status: filters.status,
  };

  const result = useSiteBookingsQuery({
    variables: {
      ...debouncedVariables,
      page: { first: pageSize },
    },
    fetchPolicy: "network-only",
    skip,
  });

  const fetchMoreAsync = async (latestData = result.data) =>
    result.fetchMore({
      variables: {
        ...debouncedVariables,
        page: {
          first: pageSize,
          after: latestData?.siteBookingsList.pageInfo.endCursor,
        },
      },
      updateQuery(prev, { fetchMoreResult }) {
        return {
          ...fetchMoreResult,
          siteBookingsList: {
            ...fetchMoreResult.siteBookingsList,
            edges: [...prev.siteBookingsList.edges, ...fetchMoreResult.siteBookingsList.edges],
          },
        };
      },
    });

  const fetchMore = () => {
    if (result.loading || loadingMore) {
      return;
    }

    setLoadingMore(true);
    fetchMoreAsync()
      .catch((e) => console.error(e))
      .finally(() => setLoadingMore(false));
  };

  const fetchAll = async () => {
    if (result.loading || loadingMore) {
      return null;
    }

    setLoadingMore(true);
    try {
      let latestData = result.data;
      let allEdges = result.data?.siteBookingsList.edges ?? [];

      while (latestData?.siteBookingsList.pageInfo.hasNextPage ?? false) {
        latestData = (await fetchMoreAsync(latestData)).data;
        allEdges = [...allEdges, ...latestData.siteBookingsList.edges];
      }

      return allEdges.map((edge) => edge.node).filter(notNullOrUndefined);
    } finally {
      setLoadingMore(false);
    }
  };

  return {
    bookings: result.data?.siteBookingsList.edges.map((edge) => edge.node).filter(notNullOrUndefined) ?? [],
    hasMoreData: result.data?.siteBookingsList.pageInfo.hasNextPage ?? false,
    error: result.error,
    loading: result.loading || loadingMore,
    fetchMore,
    fetchAll,
  };
}
