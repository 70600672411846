import { Form, MemberCard, Skeleton } from "@equiem/react-admin-ui";
import { useFormikContext } from "formik";
import React, { useContext } from "react";
import { useApolloErrorTranslation, useTranslation } from "@equiem/localisation-eq1";
import { CurrentProfile, Site, UserSearchHOC } from "@equiem/lib";
import type { BookingFormValue } from "../models/BookingFormValue";
import { useBookableResourceSiteAudiencesQuery } from "../../../generated/gateway-client";
import { BookingModalInfo } from "../contexts/BookingModalInfoProvider";

export const BookingFormUser: React.FC = () => {
  const { uuid: siteUuid } = useContext(Site);
  const { booking, resource } = useContext(BookingModalInfo);
  const { tError } = useApolloErrorTranslation();
  const { profile } = useContext(CurrentProfile);
  const fm = useFormikContext<BookingFormValue>();
  const { t } = useTranslation();
  const canProxyBook =
    resource.viewerPermissions?.canManageBookings === true &&
    (process.env.bookingMultiSiteEnabled === "true" || resource.destination.uuid === siteUuid);

  const { data, loading, error } = useBookableResourceSiteAudiencesQuery({
    variables: {
      uuid: resource.uuid,
    },
    skip: !canProxyBook,
  });

  const results = (data?.bookableResource.siteAudiences ?? []).map((audience) => audience.destination.uuid);
  const resourceSites = results.includes(resource.destination.uuid)
    ? results
    : [resource.destination.uuid].concat(results);

  if (!canProxyBook || profile?.uuid == null || resourceSites.length === 0) {
    return null;
  }
  if (loading) {
    return <Skeleton.Line width="100%" height="18px" className="mb-1" />;
  }
  if (error != null) {
    return <div>{tError(error)}</div>;
  }

  return (
    <Form.Group
      label={t("common.host")}
      showTooltip={true}
      tooltipText={t("bookings.operations.selectHostTooltip")}
      required
      error={fm.errors.host}
    >
      {booking == null && (
        <UserSearchHOC
          siteUuids={resourceSites}
          disabled={fm.isSubmitting}
          placeholder={`${t("common.searchUsers")}...`}
          onSelect={(user) => {
            fm.setFieldValue("isProxyBooking", user.uuid !== profile.uuid).catch(console.error);
            fm.setFieldValue("host", {
              uuid: user.uuid,
              email: user.email,
              companyName: user.companyV2?.name ?? "",
              lastName: user.lastName ?? "",
              firstName: user.firstName ?? "",
              profileIconUrl: user.avatar ?? undefined,
            }).catch(console.error);
          }}
        />
      )}
      {fm.values.host != null && (
        <MemberCard.Card
          className="selected-member mt-2"
          showButton={booking == null}
          buttonVariant="outline"
          buttonText={t("common.remove")}
          onButtonClick={() => {
            fm.setFieldValue("host", undefined).catch(console.error);
          }}
          email={fm.values.host.email}
          companyName={fm.values.host.companyName}
          lastName={fm.values.host.lastName}
          firstName={fm.values.host.firstName}
          profileIconUrl={fm.values.host.profileIconUrl}
        />
      )}
    </Form.Group>
  );
};
