import { CurrentProfile } from "@equiem/lib";
import { useContext } from "react";

const whitelistEmails = ["jeremy.kirkham@equiem.com.au"];

export const useShowAi = () => {
  const { profile } = useContext(CurrentProfile);

  return process.env.aiEnabled === "true" || (profile != null && whitelistEmails.includes(profile.email));
};
